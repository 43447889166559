import { AccountProvider, Client } from '@collinsonx/constants/enums';
import {
  LogoHSBC,
  LogoLK,
  LogoMastercard,
  LogoPP,
} from '@collinsonx/design-system/assets/logo';
import { Box } from '@collinsonx/design-system/core';
import useLocale from 'hooks/useLocale';
import React, { ReactNode } from 'react';

import classes from './AppLogo.module.css';

const { LK, PP } = AccountProvider;
const { Mastercard_HSBC } = Client;
const { Mastercard_MCAE } = Client;

interface AppLogoProps {
  accountProvider: AccountProvider;
  membershipType?: Client;
}

const AppLogo = ({ accountProvider, membershipType }: AppLogoProps) => {
  const translations = useLocale();
  const logoLabel = translations.generic.labels.logo;

  const logos: Partial<Record<AccountProvider | Client, ReactNode>> = {
    [LK]: (
      <Box aria-label={logoLabel} className={classes.lk}>
        <LogoLK />
      </Box>
    ),
    [Mastercard_HSBC]: (
      <Box aria-label={logoLabel} className={classes.mastercardHsbc}>
        <LogoHSBC />
      </Box>
    ),
    [Mastercard_MCAE]: (
      <Box aria-label={logoLabel} className={classes.mastercard}>
        <LogoMastercard />
      </Box>
    ),
    [PP]: (
      <Box aria-label={logoLabel} className={classes.pp}>
        <LogoPP />
      </Box>
    ),
  };

  if (membershipType && logos[membershipType]) {
    return logos[membershipType];
  }
  if (accountProvider) {
    return logos[accountProvider];
  }

  return logos[PP];
};

export default AppLogo;
