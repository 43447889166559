import { GUEST_TYPES } from 'config/productDefaultConfig';

export default {
  auth: {
    login: {
      email: {
        title: 'Introduce tu correo electrónico',
        input: {
          label: 'Ingresa tu dirección de correo electrónico',
          description:
            'Proporcione una dirección de correo electrónico que utilizaremos para comunicarnos con usted, incluida toda la información de la reserva.',
          placeholder: 'youremail@gmail.com',
        },
      },
      passwordText:
        'Le enviaremos un código de acceso de un solo uso por correo electrónico para continuar.',
      btnLogin: 'Continuar',
      error: {
        emailFormat:
          'Formato de correo electrónico incorrecto, inténtelo de nuevo',
        emailError:
          'Introduzca la dirección de correo electrónico correcta o llame al servicio de asistencia, ya que esta cuenta ya está vinculada a una dirección de correo electrónico diferente',
      },
    },
    checkCode: {
      title: 'Revisa tu correo electrónico',
      description: 'Hemos enviado un código único a ',
      wrongEmailTitle: '¿Correo electrónico incorrecto?',
      reEnterEmailLabel: 'Vuelva a ingresar su dirección de correo electrónico',
      passcodeSubtitle: 'Código de acceso de un solo uso',
      uniqueCodeText:
        'Podrá volver a enviar el código único en {count} segundos',
      btn: {
        resend: 'Reenviar',
        verify: 'Verificar',
      },
      error: {
        wrongCode:
          'El código de acceso puede ser incorrecto o caducado. Por favor, inténtelo de nuevo.',
        notVerified: {
          title: 'todo_translate_Sorry, passcode not verified',
          description:
            'todo_translate_Too many incorrect attempts were made to enter the verification passcode.',
          button: 'todo_translate_Re-enter email',
        },
        expired: {
          title: 'todo_translate_Verification code has expired',
          description:
            'todo_translate_Unfortunately the verification code has expired, so you have to restart your booking again',
          button: 'todo_translate_Restart booking',
        },
      },
    },
    signUp: {
      title: 'Detalles de la actualización',
      subtitle: 'Puedes actualizar tus datos a continuación',
      firstNameInput: {
        label: 'Nombre(s)',
        placeholder: 'John',
      },
      lastNameInput: {
        label: 'Apellido(s)',
        placeholder: 'Smith',
      },
      emailInput: {
        label: 'Dirección de correo electrónico',
      },
      marketingAgreementText:
        'Acepto recibir correos electrónicos de marketing personalizados.',
      signUpButton: 'Acceso',
      validationError: {
        invalidEmail:
          'Por favor, introduce una dirección de correo electrónico válida.',
        maxLength: 'La longitud máxima es de 255 caracteres',
        emptyName: 'El nombre no puede estar vacío',
      },
      error: {
        signUpError: 'Ocurrió un error',
        attempts: {
          title: 'Lo siento, demasiados intentos incorrectos',
          description:
            'Se hicieron 5 intentos incorrectos para ingresar el código de verificación.Vuelva a intentarlo en una hora',
          btn: {
            reEnter: 'Volver a introducir correo electrónico',
            contact: 'Soporte de contacto',
          },
        },
      },
    },
    maintenance: {
      title: 'Mantenimiento en curso',
      description:
        'Lo sentimos, el servicio de reserva no está disponible temporalmente a medida que se realiza una actualización del sistema.Vuelva a consultar más tarde o comuníquese con el soporte.',
      note: 'Por favor regrese más tarde o',
      btn: {
        support: 'Soporte de contacto',
      },
    },
    notFound: {
      title: '404 Pagina no encontrada',
      description:
        'La página que está buscando podría haber sido eliminada o no está disponible temporalmente.',
      descriptionMobile:
        'Inténtelo nuevamente o conozca las demás opciones disponibles.',
      btn: {
        returnToLounge: 'Regresar a la salón',
        support: 'Soporte de contacto',
      },
    },
  },
  lounge: {
    perPerson: 'por persona',
    errors: {
      server: {
        title: 'Lamentablemente, el servicio no está disponible actualmente',
        description:
          'Es posible que haya un error en el sistema. Inténtelo nuevamente o conozca las demás opciones disponibles.',
        btn: {
          return: 'Regresar a los salones',
        },
      },
      capacity: {
        title: 'El salón está a su capacidad',
        description: {
          known:
            'Lo sentimos, el salón seleccionado está en capacidad en este momento.Puede intentar reservar para un número menor de invitados o verificar si hay otro salón disponible.',
          notKnown:
            'Nuestras disculpas, pero la capacidad del salón para la intervalos de tiempo que seleccionó es',
        },
        solutions: {
          title: 'Puede',
          points: [
            'Cambiar el número de invitados',
            'Cambiar las ranuras de tiempo',
            'Encuentra otro salón',
          ],
        },
        btn: {
          change: 'Cambiar el número de invitados',
          return: 'Regresar a los salones',
        },
      },
      unavailable: {
        title: 'El salón no está disponible',
        description:
          'Lo siento, pero no podemos cumplir con su solicitud de reservar el salón en este momento. Pedimos disculpas por las molestias causadas. Vuelva a intentarlo más tarde o comuníquese con nuestra atención al cliente para obtener más ayuda. Gracias por su comprensión.',
      },
      terminalsMismatch: {
        title: 'Terminales no coincidiendo',
        description:
          'Tenga en cuenta que el salón que está reservando no está en la terminal que su vuelo está programado. La terminal de salón está en la terminal norte. La terminal de salida de vuelo es de South Terminaldo, ¿aún desea reservar este salón ni siquiera está en la terminal de salida?',
        btn: {
          return: 'Regresar a los salones',
          continue: 'Continuar reservando',
        },
      },
      noFlightInfoFound: {
        title: 'Lo sentimos, no podemos encontrar su vuelo',
        description:
          'Ingrese los datos de su vuelo manualmente. Tenga en cuenta que no podemos validar si el aeropuerto y la terminal coinciden.',
        btn: {
          return: 'Volver a las Salas',
          continue: 'Ingresa la hora del vuelo',
        },
      },
    },
    topBarLinks: {
      backToLounge: 'DE REGRESO ',
      faqs: 'Preguntas frecuentes',
      back: 'ATRÁS',
    },
  },
  booking: {
    flightAndGuests: {
      title: 'Entrada de vuelo e invitados',
      amendTitle: 'Modificaciones de la reserva',
    },
    flightDetails: {
      title: 'Detalles de mi vuelo',
      warning: 'El pase se puede utilizar 3 horas antes de un vuelo',
      localeValue: 'es',
      dateInput: {
        label: 'Fecha',
        placeholder: 'Fecha de vuelo',
      },
      flightTimeInput: {
        label: 'Hora de vuelo',
      },
      numberInput: {
        label: 'Número de vuelo',
        placeholder: 'E.g. EZY123',
      },
      time: {
        label: 'Tiempo de vuelo',
      },
      errors: {
        invalid_date: 'Debe proporcionar fecha de vuelo.',
        invalid_dateflight:
          'Detalles del vuelo no reconocidos.Por favor revisa e intenta de nuevo.',
        invalid_flight:
          'Detalles del vuelo no reconocidos.Por favor revisa e intenta de nuevo.',
      },
    },
    guestDetails: {
      title: '¿Quién esta viniendo?',
      description:
        'El tamaño máximo del grupo es {guests}, sin incluir {excluded}. Compruebe la disponibilidad para conocer las restricciones de la Sala VIP sobre la cantidad de {excluded} permitidos.',
      [GUEST_TYPES.adults]: {
        label: 'Adultos',
        description: '{age} años de edad',
      },
      [GUEST_TYPES.children]: {
        label: 'Niños',
        description: '{age}  años de edad',
      },
      [GUEST_TYPES.infants]: {
        label: 'Bebés',
        description: '{age}  años de edad',
      },
      [GUEST_TYPES.seniors]: {
        label: 'Personas Mayores',
        description: '{age}  años de edad',
      },
      loungeTerms: {
        line1: 'Referirse a ',
        link: 'condiciones del salón',
        line2: 'para restricciones de edad',
      },
      errors: {
        capacity:
          'La capacidad máxima de la Sala VIP es {guests} invitados en total. Cambie la cantidad de invitados.',
      },
    },
    availableSlots: {
      title: 'Tiempo estimado de llegada',
      description:
        'Los puntos de tiempo se muestran en la zona horaria de la ubicación del salón',
      placeholder: 'Tiempo de selección',
      arrivalDescription: 'Este es el momento en que llegará al salón.',
      stayTime: {
        line1: 'Como su vuelo está en ',
        line2: ', tu estadía máxima es ',
        line3: '3 horas antes',
      },
      totalPrice: {
        title: 'Precio total',
        titleAmend: 'Precio adicional a pagar',
        titleRefund: 'Cantidad a recibir',
        samePrice: 'No se requiere tarifa adicional',
      },
      panelInfoHeader: {
        date: 'Fecha',
        flightTime: 'Tiempo de vuelo',
        flightNumber: 'Número de vuelo',
      },
      busynessIndicator: {
        title: '¿Estará muy concurrida?',
        lowestLabel: 'Poco probable',
        highestLabel: 'Muy probable',
        defaultTooltipMessage:
          'La capacidad puede verse afectada en las horas pico. Seleccione el horario de llegada para conocer la capacidad de la Sala VIP',
        tooltipMessage: [
          'La Sala VIP no suele llegar a su capacidad máxima',
          'Esta Sala VIP suele tener una concurrencia baja.',
          'Es posible que esta Sala VIP esté medianamente concurrida',
          'Es posible que esta Sala VIP esté cerca de su capacidad máxima. Asegúrese su plaza reservando su visita con antelación.',
          'Es posible que esta Sala VIP esté al máximo de su capacidad. Asegúrese su plaza reservando su visita con antelación',
        ],
        errorTooltipMessage:
          'La información sobre la capacidad de la Sala VIP no está disponible actualmente',
        messageOnError:
          ' La información sobre la capacidad de la Sala VIP no está disponible actualmente. Asegúrese su plaza reservando una visita con antelación.',
        messageBeforeSelected:
          'Seleccione el horario de llegada para conocer la capacidad',
      },
      hsbcCancelationPolicy: {
        title:
          'Tenga en cuenta que se aplica una tarifa de reserva previa a los titulares de tarjetas HSBC',
        descriptionLine1:
          'Para los titulares de tarjetas HSBC Premier, este cargo por reserva previa se agregará a los cargos por pases de visita suyos y de sus invitados. Cuando llegue a la sala VIP, deberá pagar £24 por visitante.',
        descriptionLine2:
          'Para los titulares de tarjetas HSBC Premier World Elite, tenga en cuenta que, si bien recibe pases de visita gratuitos para usted y cualquier titular de tarjeta adicional (es decir, no para sus invitados), la tarifa de reserva previa actuará como una tarifa separada y también se aplicará a usted y a sus invitados. . Cuando llegue a la sala VIP, los invitados que no sean el titular principal o adicional de la tarjeta deberán pagar £24 por visitante.',
      },
      cancellationPolicy: {
        title: 'Política de cancelación',
        descriptionLine1:
          'Cancele hasta {time} horas antes de su reserva para recibir un reembolso completo.Las reservas no pueden cancelarse dentro de las {time} horas posteriores a la hora de llegada de reservas, incluidas las nuevas reservas realizadas dentro de ese rango de tiempo.',
        descriptionLine2:
          'Confirme que los detalles son correctos antes de realizar el pago.',
      },
      btn: 'CONFIRMAR',
      amendBtn: 'CONTINUAR CON LOS CAMBIOS',
      errors: {
        airportMismatch: {
          title: 'Los aeropuertos no coinciden',
          description:
            'El salón que está reservando no está en el mismo aeropuerto de su vuelo está programado para partir.',
        },
        terminalMismatch: {
          title: 'Las terminales no coinciden',
          description:
            'El salón que está reservando no está en la misma terminal de que su vuelo está programado para partir.',
          continueBtn: 'CONTINUAR CON LA RESERVA',
        },
        noAmendChanges: {
          title: 'Seleccione otros detalles',
          description:
            'No realizó ningún cambio. Modifique su reserva o abandone el proceso de modificación',
          btn: 'MODIFICAR RESERVA',
        },
        confirmation: '¿Todavía quieres seguir adelante con esta reserva?',
        estimatedTime: 'Seleccione la hora de llegada estimada',
        availabilityUnknown: {
          title: 'La disponibilidad es desconocida',
          description:
            'Nuestras disculpas, pero la capacidad para el tiempo que seleccionó es desconocida.',
          solutions: {
            title: 'Puede',
            points: [
              'Cambiar el número de invitados',
              'Cambiar la ranura de tiempo',
              'Encuentra otro salón',
            ],
          },
          endText: 'O inténtalo de nuevo más tarde',
        },
        btn: {
          edit: 'Editar reserva',
          return: 'Regresar a los salones',
        },
      },
    },
    declineAmend: {
      title:
        'Lamentablemente, esta reserva no se puede modificar dentro de las {time} horas del horario de llegada reservado.',
    },
    confirmBooking: {
      title: 'Resumen de reservas',
      amendTitle: 'Resumen de reserva',
      text: 'Confirme que los detalles son correctos antes de realizar el pago.',
      btn: 'Ir al pago',
      loadingModal: {
        title: 'La página se está cargando',
        description:
          'Espere mientras la página termina de cargarse. Esto puede tardar unos segundos en completarse. Gracias por su paciencia',
      },
    },
    entitlements: {
      title: 'Beneficios de reserva',
      infoModal: {
        title: 'Beneficios de reserva',
        text: 'Los beneficios de reserva se otorgan a los titulares de tarjetas Platinum y Centurion en los Estados Unidos, cortesía de American Express. Estos beneficios permiten a los titulares de tarjetas reservar Salas VIP de Priority Pass del Reino Unido elegibles. Al reservar una Sala VIP elegible, los titulares de tarjetas podrán usar estos beneficios de reserva para reservar una plaza tanto para ellos mismos como para sus invitados.',
        btnClose: 'CERRAR',
      },
      countAndExpiryDate:
        'Tiene {count} beneficios de reserva restantes, válidos hasta el {date}',
      inputLabel:
        'Seleccione la cantidad de beneficios que le gustaría usar para esta reserva',
    },
    payment: {
      amendmentPaymentPolicy:
        'Se le cobrará el monto total de la modificación de su reserva existente y recibirá un reembolso por el monto total de su reserva original',
      btnGoPayment: 'Ir al pago',
      title: 'Información del pago',
    },
    confirmationPayment: {
      title: 'Confirmación de reserva',
      amendTitle: 'Confirmación de modificación de la reserva',
      processing: {
        beingProcessed: {
          title: 'El pago se está procesando',
          description: {
            line1: 'Su pago por ',
            line2: 'está siendo procesado.',
            line3:
              'No actualice la página, puede tardar unos minutos en completarse. ',
          },
        },
        unsuccessful: {
          title: 'El pago no tiene éxito',
          description:
            'Por favor, no deje esta pantalla ni cierre su navegador hasta que termine la acción',
        },
      },
      outcome: {
        succesful: {
          title: '¡Buenas noticias! Tu reserva ha sido confirmada',
          titleAmend: '¡Buenas noticias! Su reserva se modificó correctamente',
          refundExplainedLine1:
            'Recibirá un reembolso por el monto total de su reserva original.',
          refundExplainedLine2:
            'Su reembolso se realizará en la tarjeta de pago que usó para pagar la reserva original dentro de los próximos 10 días hábiles.',
          refundText:
            ' Redujo la cantidad de visitantes en su reserva. Su reembolso se realizará en la tarjeta de pago que usó para pagar la reserva original dentro de los próximos 10 días hábiles.',
          reference: {
            label: 'Referencia de reserva',
          },
          emailConfirmationLabel:
            'Se ha enviado un correo electrónico de confirmación a',
          entitlementsRedeemed: 'Beneficio{plural} canjeado{plural}',
          entitlementsRemaining:
            'Tiene {count} beneficios de reserva restantes.',
          description:
            'Nuestras disculpas, se produjeron error durante el proceso de pago y su pago no fue procesado.Le solicitamos amablemente que haga una nueva reserva o repita su pago.',
          importantNotes: {
            title: 'Notas importantes',
            notes: {
              one: 'Recuerde traer su número de referencia de reserva, pase de embarque e ID de foto junto con su tarjeta de membresía {membershipType} o método de acceso elegible para registrarse en el salón.',
              two: 'La estadía máxima es de 3 horas antes de su tiempo de vuelo.',
              three:
                'La cancelación debe hacerse al menos {time} horas antes de la fecha y hora de su visita para recibir un reembolso.No se emitirá reembolso después de este tiempo.',
            },
            amendedNote:
              'Se le cobró el monto total de la modificación de su reserva existente. Recibirá un reembolso por el monto total de su reserva original en la tarjeta de pago que usó para pagar la reserva original dentro de los próximos 10 días hábiles.',
          },
          btn: {
            download: 'Descargar confirmación de reserva',
            return: 'Regreso a la salón',
          },
        },
        notConfirmed: {
          title: 'Su pago no ha confirmado',
          description:
            'Nuestras disculpas, se produjeron error durante el proceso de pago y su pago no fue procesado.Le solicitamos amablemente que haga una nueva reserva o repita su pago.',
          btn: {
            payment: 'Pago repetido',
            return: 'Regreso a la salón',
          },
        },
        delay: {
          title: 'Retraso de confirmación de reserva',
          description:
            'Todavía no podemos confirmar su reserva, le enviaremos un correo electrónico una vez que se confirme su reserva',
          btn: 'Regresar a los salones',
        },
        delayError: {
          title: 'No recibió confirmación',
          description:
            'Todavía no podemos confirmar su reserva, le enviaremos un correo electrónico una vez que se confirme su reserva',
          btn: 'Regresar a los salones',
        },
        declined: {
          title: 'Tu reserva ha sido rechazada',
          description:
            'Disculpas por la demora en el procesamiento.Desafortunadamente, no pudimos confirmar su reserva.Considere reservar otro espacio de tiempo.',
          btn: {
            return: 'Regresar a los salones',
            select: 'Seleccione otro momento',
          },
        },
      },
    },
    cancellation: {
      title: 'Cancelación de reserva',
      description: 'Estás a punto de cancelar la reserva, ¿estás seguro?',
      reference: 'Referencia de reserva:',
      btnCancel: 'Cancelar la reserva',
      btnClose: 'CERRAR',
      policy: {
        title: 'Política de cancelación',
        description:
          'Cancele hasta {time} horas antes de su reserva para recibir un reembolso completo.Las reservas no pueden cancelarse dentro de las {time} horas posteriores a la hora de llegada de reservas, incluidas las nuevas reservas realizadas dentro de ese rango de tiempo.',
      },
      unsuccesful:
        'Lo sentimos, esta reserva no se puede cancelar dentro de las {time} horas posteriores a la hora de llegar a la reserva',
      btn: 'Confirmar',
      errors: {
        notFound: 'La reserva no se puede encontrar',
        alreadyCancelled: 'La reserva ya ha sido cancelada',
        notOwned:
          'Lo siento, algo salió mal con su reserva, intente nuevamente más tarde o comuníquese con el soporte',
        failed:
          'Lo siento, algo salió mal con su reserva, intente nuevamente más tarde o comuníquese con el soporte',
        notAllowed:
          'Lo sentimos, esta reserva no se puede cancelar dentro de las {time} horas posteriores a la hora de la llegada de la reserva.',
        wrong:
          'Lo siento, algo salió mal con su reserva, intente nuevamente más tarde o contacte a los soportes',
      },
      confirmation: {
        title: {
          Cancel: 'Tu reserva ha sido cancelada',
          Failed:
            'Su cancelación de reserva ha fallado, comuníquese con nuestro equipo',
          NotCancel:
            'Su reserva no podría cancelarse, comuníquese con nuestro equipo',
        },
        email: 'Se ha enviado un correo electrónico de confirmación a',
        refund: {
          failed:
            'No se pudo realizar el reembolso. Póngase en contacto con su banco o con el emisor de su tarjeta para obtener asistencia.',
          successful:
            ' Su pago por esta reserva se reembolsará dentro de los 10 días.',
        },
        entitlementsRefund:
          'Los beneficios de reserva que usó en su reserva volverán a estar disponibles pronto.',
        entitlementsExpiration:
          'Recuerde que sus beneficios existentes vencerán el {date}',
        reBook:
          'Si no tenía la intención de cancelar, vuelva a reservar a través de {accountProvider}. Esperamos verte la próxima vez.',
        moreInformation:
          'Para obtener más información, visite nuestra página de {faq:preguntas frecuentes}',
        loading: 'Cargando',
      },
    },
    checkAvailability: {
      arrivalTitle: 'Selección de tiempo de llegada',
      amendTitle: 'Enmienda de tiempo de llegada',
      notFoundError: 'Algo salió mal. Por favor, vuelva a intentarlo',
      btn: 'Verifique la disponibilidad',
    },
    failureBooking: {
      declined: {
        title: 'Tu reserva ha sido rechazada.',
        description:
          'Lo sentimos, pero durante el proceso de pago cambió la capacidad del salón y ya no podemos confirmar su reserva.Se le reembolsará cualquier pago realizado.',
        note: 'Considere reservar otro espacio de tiempo o verificar si hay otro salón disponible.',
        btn: {
          selectAnotherTime: 'Seleccione otro momento',
          returnToLounge: 'Regresar a los salones',
        },
      },
    },
    confirmationPDF: {
      title: 'Confirmación de reserva',
      description: {
        line1: '¡Buenas noticias!Tu reserva para',
        line2: 'en',
        line3: 'ha sido confirmado.',
      },
      bookingDetails: {
        title: 'Tus detalles de la reserva',
        reference: 'Referencia de reserva:',
        date: 'Fecha:',
        flightNumber: 'Número de vuelo:',
        timeOfArrival: 'Tiempo estimado de llegada:',
      },
      guestDetails: {
        title: '¿Quién esta viniendo?',
        [GUEST_TYPES.adults]: 'Adultos',
        [GUEST_TYPES.children]: 'Niños',
        [GUEST_TYPES.infants]: 'Bebés',
        [GUEST_TYPES.seniors]: 'Personas Mayores',
      },
      entitlements: {
        redeemed: 'Beneficio de reserva canjeado:',
        remaining: 'Beneficios de reserva restantes:',
        validUntil: 'Los beneficios de reserva son válidos hasta el:',
      },
      price: 'total',
      importantNotes: {
        title: 'Notas importantes',
        checkInNote:
          'Recuerde traer su número de referencia de reserva, pase de embarque e ID de foto junto con su tarjeta de membresía {membershipType} o método de acceso elegible para registrarse en el sala.',
        maximumStay:
          'La estancia máxima es de 3 horas antes de su tiempo de vuelo.',
        cancelText:
          'La cancelación debe hacerse al menos {time} horas antes de la fecha y hora de su visita para recibir un reembolso. No se emitirá reembolso después de este tiempo. ',
        amendText: 'Por favor, utilice el enlace si desea ',
      },
      amendLink: 'Modificar la reserva',
      cancelLink: 'Haga clic aquí para cancelar su reserva',
      forwardText: 'Esperamos verlos allí!',
      loadingText: 'Documento de carga ...',
      error:
        '¡Se produjo un error al generar su confirmación de reserva! Inténtalo de nuevo.',
    },
    failureAmendmentRefund: {
      title: 'Sus enmiendas de reserva han sido rechazadas',
      description:
        'Lamentamos durante el proceso de enmienda que cambió la capacidad para el salón y ya no podemos confirmar su enmienda de reserva.Tu reserva sigue siendo la misma.Si aún desea cambiar su reserva, intente nuevamente',
    },
  },
  fastTrack: {
    errors: {
      capacity:
        'La capacidad máxima del Fast Track es un total de {guests} invitados. Cambia el número de invitados.',
    },
    estimatedArrivalTimeDescription:
      'Los horarios se muestran en el huso horario del Fast Track Lane',
    importantNotes: {
      one: 'Tenga a mano el número de referencia de su reserva, el correo electrónico de confirmación, la tarjeta de embarque y el pasaporte para evitar cualquier demora al acceder al Fast Track Lane.',
      two: 'Si su correo electrónico de confirmación contiene un código QR escaneable, téngalo listo para que se lo escaneen cuando llegue al Fast Track Lane.',
      three:
        'Si desea cancelar su reserva, deberá hacerlo con al menos 2 días de antelación a la fecha de su visita para recibir un reembolso. No recibirá ningún reembolso si cancela pasado ese plazo.',
    },
    policies: {
      cancellation:
        'Cancele hasta 2 días antes de la reserva del servicio de Fast Track para recibir un reembolso completo. Las reservas del servicio de Fast Track no se pueden cancelar dentro de los 2 días del horario de llegada indicado, lo que incluye las reservas nuevas realizadas durante ese período. No puede modificar una reserva del servicio de Fast Track. Si desea realizar algún cambio, cancele su reserva original y realice una nueva.',
      terms: {
        line1: 'Consulte las ',
        link: 'condiciones del servicio de Fast Track',
        line2: 'para restricciones de edad',
      },
      guests:
        'El tamaño máximo del grupo es {guests}, sin incluir niños. Compruebe la disponibilidad para conocer las restricciones de Fast Track sobre la cantidad de niños permitidos.',
    },
    timeSlotsDisclaimer:
      'Esta es la hora a la que llegará a la Fast Track Lane.',
  },
  generic: {
    labels: {
      logo: 'todo_translate_App logo',
      downloadPdf: 'todo_translate_Download PDF',
      editBooking: 'todo_translate_Edit booking',
      selectTimeSlot: 'todo_translate_Select time slot',
      flightDate: 'todo_translate_Flight Date',
      flightNumber: 'todo_translate_Flight Number',
      flightTime: 'todo_translate_Flight Time',
      loungeName: 'todo_translate_Lounge name',
      codeInput: 'todo_translate_One time passcode',
      editButton: 'todo_translate_Edit button',
      loginEmail: 'todo_translate_Login email address',
      firstName: 'todo_translate_First name',
      lastName: 'todo_translate_Last name',
      adults: 'todo_translate_Adults',
      children: 'todo_translate_Children',
      infants: 'todo_translate_Infants',
      decrementAdults: 'todo_translate_Decrement Adults',
      incrementAdults: 'todo_translate_Increment Adults',
      selectedAdults: 'todo_translate_Selected Adults',
      decrementChildren: 'todo_translate_Decrement Children',
      incrementChildren: 'todo_translate_Increment Children',
      selectedChildren: 'todo_translate_Selected Children',
      decrementInfants: 'todo_translate_Decrement Infants',
      incrementInfants: 'todo_translate_Increment Infants',
      selectedInfants: 'todo_translate_Selected Infants',
    },
    error: {
      latestAppVersion:
        'Puede haber un error en el sistema. Asegúrese de actualizar a la última versión de la aplicación.',
      webTitle:
        'Puede haber un error en el sistema. Inténtalo de nuevo o explora otras opciones.',
      loungeNoFound:
        'Lo sentimos, no podemos encontrar la sala que solicitaste.',
      tokenNoFound: 'Lo sentimos, no se encontró el token',
    },
    branding: {
      MCAECustomBrandName: 'Mastercard Airport Experiences',
    },
  },
};
